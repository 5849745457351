import React from 'react';

export default function PartnersCard(props) {
	let tierColor = {
		Silver: '#8bb0d4',
		Gold: '#e1b158',
		Platinum: '#c6c8cb',
		Bronze: '#ce8b41',
		Alliance: '#404040',
		'Emerging Partner': '#58cc88',
		Startup: '#58cc88',
        'Technology': '#e2834d'
	};
	let title = props.cardinfo.title;
	if (title) {
		title = title.replace(/\|(.*)/g, '');
	}

	let description = props.cardinfo.description;
	if (description && description.length > 115) {
		description = description.slice(0, 112) + '...';
	}

	return (
		<div className="block">
			<div className="block-items">
				<a href={props.cardinfo.link}>
					<div className="card">
						<div className="card-image">
							<img src={props.cardinfo.img ? props.cardinfo.img : ''} aria-hidden="true" alt={title} />

							<div
								className="category 
                            partner__category"
								style={{ backgroundColor: !props.cardinfo.partnerTier && !props.cardinfo.solutionType ? '' : 'rgba(239, 239, 239, 0.6)' }}>
								{props.cardinfo.pageType === 'solutions' && props.cardinfo.solutionType ? (
									<span></span>
								) : props.cardinfo.pageType === 'partners' && props.cardinfo.partnerTier ? (
									<span className="circle" style={{ backgroundColor: tierColor[props.cardinfo.partnerTier] }}></span>
								) : (
									<span></span>
								)}
								<span className="tier">
									{props.cardinfo.pageType === 'solutions' && props.cardinfo.solutionType
										? props.cardinfo.solutionType.toUpperCase()
										: props.cardinfo.pageType === 'partners' && props.cardinfo.partnerTier
										? props.cardinfo.partnerTier.toUpperCase()
										: ''}
								</span>
							</div>
							{props.cardinfo.releaseReady ? <span className="pill font-size--3">{props.cardinfo.releaseReady}</span> : ''}
						</div>
						<div className="content">
							<div className="text-container">
								<p className="title">{title}</p>
								<p className="context font-size--3">{props.cardinfo.location}</p>
								<div className="description font-size--1 avenir-light">{description}</div>
							</div>
						</div>
						<div className="link-url">
							<span className="font-size--1">Read More</span>
						</div>
					</div>
				</a>
			</div>
		</div>
	);
}
