export default class Utils {
	static handleFiltersToHashUrl(sField, sValue) {
		//TODO: remove
		// call with no args to clear filters.
		let initSQ = '';
		let defaultQuery = 'search=';
		let updatedHash = null;
		let currHash = decodeURI(location.hash);
		if (currHash.indexOf(defaultQuery) > -1) {
			let searchRegx = /search=(\w|\s|-|\.|#|\+)+/gm;
			let searchQArr = currHash.match(searchRegx);
			if (searchQArr[0].length) {
				initSQ = searchQArr[0]; // My need to codeURI
			}
		}
		if (sField && sValue) {
			let filterdHash = `${sField}=${sValue}`;
			updatedHash = initSQ.length ? `${initSQ}&${filterdHash}` : filterdHash;
		} else if (initSQ) {
			updatedHash = initSQ;
		}
		return updatedHash;
		// location.hash = updatedHash;
	}

	static getCurrHashState() {
		//TODO: remove
		let currHash = decodeURI(location.hash);
		let hashMultiple = currHash.indexOf('&') > -1;
		let activeHashParams = { q: '', categFields: '' };

		let defaultQuery = 'search=';
		let searchRegx = /search=(\w|\s|-|\.|#|\+)+/gm;

		if (currHash.indexOf(defaultQuery) > -1) {
			let searchQArr = currHash.match(searchRegx);
			if (searchQArr[0].length) {
				activeHashParams.q = searchQArr[0].replace(defaultQuery, ''); // My need to codeURI
			}
		}

		// remove serch
		let catParams = currHash.replace(searchRegx, '');
		if (catParams.indexOf('=') > -1) {
			let equalSignRegx = /=/gm;
			let amperRegx = /&/gm;
			let hashRegx = /#/gm;
			catParams = catParams.replace(/#/gi, '');
			catParams = catParams.indexOf('&') == 0 ? catParams.slice(1) : catParams;
			catParams = catParams.replace(amperRegx, '|');
			catParams = catParams.replace(hashRegx, '|');
			catParams = catParams.replace(equalSignRegx, ':');
			activeHashParams.categFields = catParams;
		}

		return activeHashParams;

		// location.hash = updatedHash;
	}

	static handleFilterHashUpdate(sField, sValue) {
		let currHash = decodeURI(location.hash);

		// This is for pagination, removing page= in url.
		let pageQuery = 'page=';
		let pageRegx = /page=(\w|\s)+/gm;
		if (currHash.indexOf(pageQuery) > -1) {
			let searchQArr = currHash.match(pageRegx);
			if (searchQArr[0].length) {
				currHash = currHash.replace(pageRegx, '');
			}
		}

		let hasActiveHash = currHash.indexOf('=');
		let currQuery = `${sField}=${sValue}`;
		let filterWithAmp = new RegExp(`&${currQuery}`, 'gm');
		let firstFilter = new RegExp(`${currQuery}`, 'gm');

		// hash has the same filter - remove

		if (filterWithAmp.test(currHash)) {
			currHash = currHash.replace(`&${currQuery}`, '');
			return removeStartingAmp(currHash);
		} else if (firstFilter.test(currHash)) {
			currHash = currHash.replace(`${currQuery}`, '');
			return removeStartingAmp(currHash);
		}
		// hash doesn't have same filter add it
		currQuery = hasActiveHash ? `&${currQuery}` : currQuery;
		currQuery = `${currHash}${currQuery}`;
		return removeStartingAmp(currQuery);

		function removeStartingAmp(txt) {
			txt = txt.indexOf('#') == 0 ? txt.slice(1) : txt;
			return txt.indexOf('&') == 0 ? txt.slice(1) : txt;
		}
	}

	static removeSearchQuery() {
		// call with no args to clear filters.
		let currHash = decodeURI(location.hash);

		// This is for pagination, removing page= in url.
		let pageQuery = 'page=';
		let pageRegx = /page=(\w|\s)+/gm;
		if (currHash.indexOf(pageQuery) > -1) {
			let searchQArr = currHash.match(pageRegx);
			if (searchQArr[0].length) {
				currHash = currHash.replace(pageRegx, '');
			}
		}

		let searchRegx = /search=(\w|\s|-|\.|#|\+)+/gi;

		if (searchRegx.test(currHash)) {
			return currHash.replace(searchRegx, '').replace('&', '');
		} else {
			return currHash;
		}
	}

	static getCurrFiltersArr(activeFilters) {
		// "productType:Apps|platform:SaaS"
		let filterArr = activeFilters[0].split('|');
		let filters = filterArr.map((filter) => {
			filter = filter.replace(/#/gi, '');
			let filterObj = {};
			let filterArr = filter.split(':');
			filterObj.cat = filterArr[0];
			filterObj.value = filterArr[1];

			filterObj.label = Utils.getFilterLabel(filterObj.value);
			return filterObj;
		});
		return filters;
	}

	static filterprops = '';

	static getfilterData(props) {
		Utils.filterprops = props;
	}

	static getFilterLabel(activefilterValue) {
		let rawFilterData = Utils.filterprops.filtersData;
		let activeFilterLabel = [];

		let i = 0;
		while (i < rawFilterData.length && activeFilterLabel.length <= 0) {
			let item = rawFilterData[i];
			if (item.subFilters) {
				let parentFilter = item.subFilters;

				parentFilter.forEach((childitem) => {
					let subFilterLabel = childitem.label;
					let subFilterValue = childitem.value;
					if (activefilterValue == subFilterValue) {
						activeFilterLabel.push(subFilterLabel);
					} else if (activefilterValue == subFilterLabel) {
						activeFilterLabel.push(subFilterLabel);
					}
				});
			}

			if (item.name.indexOf('=') > -1) {
				let filterLabel = item.label.trim();
				let [filterField, filterName] = item.name.split('=');
				filterField = filterField.trim();
				filterName = filterName.trim();
				if (activefilterValue == filterName) {
					activeFilterLabel.push(filterLabel);
				} else if (activefilterValue == filterLabel) {
					activeFilterLabel.push(filterLabel);
				}
			}

			i++;
		}

		return activeFilterLabel;
	}

	static async getCountryCode() {
		let countryCode = '';

		if (typeof geoip2 !== 'undefined') {
			await callInsights();
		} else {
			console.error('no geoip2 Object present!!!');
		}

		async function callInsights() {
			return await new Promise((resolve) => {
				geoip2.insights((insight) => {
					countryCode = insight.country.iso_code;
					resolve();
				});
				// if no response is available from geiop2
				if (countryCode == '') {
					setTimeout(function () {
						// if localhost
						if (location.host.indexOf(':4502') > -1) {
							countryCode = 'US';
							resolve();
						} else {
							console.error('ERROR: [geoip2 is not responding]');
							resolve();
						}
					}, 3000);
				}
			});
		}

		return countryCode;
	}
}

export class FilterAccessibility {
	constructor(filterTree) {
		let categories = [].slice.call(filterTree.querySelectorAll('.collapse'));

		if (categories.length > 0) {
			categories.forEach((category) => {
				this.findContent(category);
				// this.listenElmt(category);
			});
		}
	}

	toggleElmt(el) {
		if (el.getAttribute('aria-hidden') == 'true') {
			el.setAttribute('aria-hidden', 'false');
		} else {
			el.setAttribute('aria-hidden', 'true');
		}
	}

	findContent(category) {
		let filterContent = category.getElementsByTagName('label')[0];
		if (filterContent != null) {
			let lastFilterContent = category.lastElementChild;

			filterContent.addEventListener('keypress', (e) => {
				// let key = e.which || e.keyCode;
				this.toggleElmt(lastFilterContent);
				this.toggleElmt(e.target);
			});

			filterContent.addEventListener('click', (e) => {
				this.toggleElmt(lastFilterContent);
				this.toggleElmt(e.target);
			});
		}
	}
}

export function filterDomManipulations() {
	// media query event handler
	let isDesktop = window.matchMedia('(min-width: 1281px)');
	let isTablet = window.matchMedia('(min-width: 768px) and (max-width: 1280px)');
	let isMobile = window.matchMedia('(max-width: 767px)');

	if (isDesktop.matches) {
	} else if (isTablet.matches) {
		isTablet.addListener(TabletFilter);
		TabletFilter(isTablet);
	} else if (isMobile.matches) {
		MobileFilter(isMobile);
	}

	function animateFilter() {
		var popoverFilter = document.querySelector('.search-results-app-filter');
		popoverFilter.setAttribute('data-expanded', 'false');
	}

	// tablet
	function TabletFilter(isTablet) {
		if (isTablet.matches) {
			var popoverFilter = document.querySelector('.search-results-app-filter');
			var filterLink = document.querySelector('.filter-icon');
			var filterClose = document.querySelector('.filter-heading-text');
			var filterCloseSection = document.querySelector('.search-results-app-cards');

			if (popoverFilter) {
				filterLink.onclick = function (event) {
					event.preventDefault();
					if (popoverFilter.getAttribute('data-expanded') === 'false') {
						popoverFilter.setAttribute('data-expanded', true);
					} else if (popoverFilter.getAttribute('data-expanded') === 'true') {
						popoverFilter.setAttribute('data-expanded', false);
					}
				};
				filterClose.onclick = function (event) {
					event.preventDefault();
					if (popoverFilter.getAttribute('data-expanded') === 'true') {
						popoverFilter.setAttribute('data-expanded', false);
					}
				};

				var elements = document.querySelectorAll('.search-results-app-cards, .simple-search, .sra-options, .omniheader');
				for (var i = 0; i < elements.length; i++) {
					elements[i].addEventListener('click', function () {
						popoverFilter.setAttribute('data-expanded', false);
					});
				}
			}
		}
	}

	// mobile
	function MobileFilter(isMobile) {
		if (isMobile.matches) {
			var popoverFilter = document.querySelector('.search-results-app-filter');
			var filterLink = document.querySelector('.filter-heading');
			var filterMobileClose = document.querySelector('.filter-heading-close-mobile');
			var focusState = document.querySelector('.filter-content-navigation');

			if (popoverFilter) {
				filterLink.onclick = function (event) {
					event.preventDefault();
					if (popoverFilter.getAttribute('data-expanded') === 'false') {
						popoverFilter.setAttribute('data-expanded', true);
						focusState.focus();
					} else if (popoverFilter.getAttribute('data-expanded') === 'true') {
						popoverFilter.setAttribute('data-expanded', false);
					}
				};
				filterMobileClose.onclick = function (event) {
					event.preventDefault();
					if (popoverFilter.getAttribute('data-expanded') === 'true') {
						popoverFilter.setAttribute('data-expanded', false);
					}
				};
			}
		}
	}
}

export function filterFadeOut(el) {
	const filterMaxHeight = 250;
    if(el && !Array.isArray(el)) {
      if (el.scrollHeight > filterMaxHeight) {
          el.classList.add('fadeout');
      } else {
          el.classList.remove('fadeout');
      }
    }
}
